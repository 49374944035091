/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/* roboto-condensed-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'RobotoCondensedLight';
  src: url('/extras/fonts/roboto-condensed-v27-latin-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'RobotoCondensedRegular';
  src: url('/extras/fonts/roboto-condensed-v27-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'RobotoCondensedBold';
  src: url('/extras/fonts/roboto-condensed-v27-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* ephesis-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Ephesis';
  src: url('/extras/fonts/ephesis-v9-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 12px;
  margin-bottom: 12px;
}
.unit > .body,
.unit > form > .body {
  margin: 0 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0;
  margin-bottom: 0;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #4d4d4d;
  color: #4d4d4d;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #b3b3b3;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 1px;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
  background: #ccc;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #e6e6e6;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #404040;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #690014;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #820019;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #820019;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 765px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #e6e6e6;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 4px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: normal;
    font-family: 'RobotoCondensedBold', helvetica, sans-serif;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 4px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 4px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(130, 0, 25, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 16px;
  font-size: 1.6rem;
  width: 100%;
  background-color: transparent;
  color: #000;
  font-family: 'RobotoCondensedLight', helvetica, sans-serif;
  line-height: 1.5;
}
#desk {
  display: none !important;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 1400px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.desk.small {
  width: 600px;
}
.desk.mooddesk {
  display: none;
}
.cb-layout2 .desk.mooddesk {
  display: block;
}
#home {
  float: left;
  width: 220px;
  position: relative;
  z-index: 2;
}
.logo {
  width: 100%;
  height: auto;
}
#head {
  float: left;
  width: 100%;
  margin-top: 41px;
}
.cb-layout2 #head {
  margin-top: 74px;
}
#wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#cb-elastic-container {
  position: relative;
  float: left;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
  background: #fff;
}
#cb-elastic-content {
  float: left;
  width: 100%;
  border-bottom: 1px solid #949a9e;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
.wave {
  position: absolute;
  left: -2px;
}
#multimood {
  float: left;
  width: 100%;
  position: relative;
}
#content {
  float: left;
  width: 100%;
}
#maincontent {
  float: left;
  width: 100%;
}
#basecontent {
  float: left;
  width: 100%;
}
#southcontent {
  float: left;
  width: 100%;
}
#footer {
  float: left;
  width: 100%;
  margin-top: 14px;
  background: url(/images/base-pure.png) repeat 0 0;
}
#brands {
  float: left;
  width: 100%;
  text-align: center;
  line-height: 0;
  font-size: 0;
  vertical-align: middle;
}
.brand {
  display: inline-block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  vertical-align: bottom;
  margin: 18px 12px;
}
.brandwidth {
  float: left;
  width: 100%;
  border-bottom: 1px solid #000;
  padding: 30px 0;
  text-align: center;
}
.basler {
  width: 216px;
  height: 44px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: url(/images/brands/basler-2022-11-24.svg) no-repeat 0 0;
}
.auszeichnung {
  width: 160px;
  height: 24px;
  background: none;
  position: relative;
}
.auszeichnung a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.auszeichnung iframe {
  float: left;
  width: 100%;
}
.tripadviser {
  width: 69px;
  height: 44px;
  background: url(/images/brands/tripadviser.svg) no-repeat 0 0;
}
.best {
  width: 90px;
  height: 68px;
  background: url(/images/brands/best.svg) no-repeat 0 0;
}
.booking iframe {
  float: left;
}
.prix {
  width: 68px;
  height: 68px;
  background: url(/images/brands/prix.png) no-repeat 0 0;
}
.typically {
  width: 91px;
  height: 51px;
  background: url(/images/brands/typically.png) no-repeat 0 0;
}
.whiteline {
  width: 74px;
  height: 37px;
  background: url(/images/brands/whiteline2.svg) no-repeat 0 0;
}
.ql {
  width: 68px;
  height: 68px;
  background: url(/images/brands/ql.svg) no-repeat 0 0;
}
.karl {
  width: 74px;
  height: 80px;
  background: url(/images/brands/karl.svg) no-repeat 0 0;
}
.tour {
  width: 54px;
  height: 67px;
  background: url(/images/brands/tour.svg) no-repeat 0 0;
}
.clean {
  width: 68px;
  height: 68px;
  background: url(/images/brands/clean-and-safe.svg) no-repeat 0 0;
}
.vcard {
  float: left;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  padding: 13px 0;
}
.vcard .sep {
  margin: 0 3px;
}
.vcard .postal-code {
  margin-left: -4px;
}
#social {
  float: left;
  display: block;
  width: 100%;
  text-align: center;
  line-height: 0;
  font-size: 0px;
  margin-top: 25px;
}
#social .meta {
  margin: 0 12px;
  display: inline-block;
  width: 29px;
  height: 25px;
  background: url(/images/social.svg) no-repeat 0 0;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
#social .meta:hover,
#social .meta:focus {
  opacity: 0.6;
  cursor: pointer;
}
#social .meta.service_vimeo {
  margin-left: 0;
}
#social .meta.service_youtube {
  width: 60px;
  background-position: -29px 0;
}
#social .meta.service_facebook {
  width: 25px;
  margin-right: 0;
  background-position: -89px 0;
}
#cmsbox {
  float: left;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 13px;
  margin: 30px 0;
}
#cmsbox .meta {
  color: #000;
  padding: 0 4px;
}
#cmsbox .meta:hover,
#cmsbox .meta:focus {
  color: #820019;
  text-decoration: none;
}
.moodshadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  z-index: 3;
  opacity: 0.5;
  display: none;
  background-image: -moz-linear-gradient(50% 0% -90deg, #000000 0%, transparent 100%);
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0, #000000), color-stop(1, transparent));
  background-image: -webkit-linear-gradient(-90deg, #000000 0%, transparent 100%);
  background-image: -o-linear-gradient(-90deg, #000000 0%, transparent 100%);
  background-image: linear-gradient(180deg, #000000 0%, transparent 100%);
}
.bookingmaskwidth {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 10;
  margin-top: -50px;
}
.bookingmaskwidth.cb-navi-sticky-clone {
  display: none !important;
}
#bookingmask {
  float: right;
  width: 210px;
  background: url(/images/booking.png) repeat 0 0;
  height: 50px;
  overflow: hidden;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
#bookingmask .service_booking {
  display: block;
  text-align: center;
  color: #000;
  font-size: 34px;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
  line-height: 50px;
}
#bookingmask .service_booking:hover {
  background: #fff;
  text-decoration: none;
}
#bookingmask .service_booking:focus {
  text-decoration: none;
}
.bookingform {
  float: left;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 25px;
}
.bookingform label {
  display: block;
  line-height: 1;
  padding-top: 8px;
}
.bookingform input {
  display: block;
  width: 100%;
  height: 26px;
  padding: 0 5px;
  margin-top: 4px;
}
.bookingform input.qfButton {
  margin-top: 10px;
  line-height: 1;
  height: 32px;
  text-transform: uppercase;
  font-size: 21px;
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
}
.bookingform input.qfButton:hover,
.bookingform input.qfButton:focus {
  cursor: pointer;
  color: #820019;
}
h1 {
  text-align: center;
  font-size: 34px;
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
  line-height: 1.35;
  text-transform: uppercase;
}
h1 i {
  font-style: normal;
  color: #820019;
}
.cb-layout2 h1 {
  font-family: 'Ephesis', helvetica, sans-serif;
  color: #820019;
  text-transform: none;
  font-size: 42px;
  font-weight: normal;
}
h3 {
  font-size: 42px;
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 17px 0 14px;
}
.base .pure h3,
.south .pure h3 {
  color: #60666c;
}
.base .seam h3,
.south .seam h3 {
  color: #820019;
}
.base .flat h3,
.south .flat h3 {
  color: #820019;
}
h4 {
  font-size: 42px;
  font-family: 'Ephesis', helvetica, sans-serif;
  line-height: 1.2;
  font-weight: normal;
}
.base .flat h4,
.south .flat h4 {
  color: #820019;
}
h5 {
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
}
h6 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  line-height: 1.3;
}
h6 strong {
  font-size: 34px;
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
}
strong {
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
}
.loud {
  padding: 0.2em 0.4em;
  background-color: #bfbfbf;
  color: #fff;
  /* redo with @basecolor & guard */
}
.loud > .open {
  color: #e6e6e6;
  /* redo with @basecolor */
}
.loud > .open:hover,
.loud > .open:focus {
  color: #bfbfbf;
}
.pale {
  color: #4d4d4d;
}
.skew {
  text-align: left;
  line-height: 1.3;
}
.skew strong {
  text-transform: uppercase;
}
.text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.gist,
.dent {
  list-style: disc;
}
a {
  color: #820019;
  text-decoration: none;
}
a:hover,
a:focus {
  text-decoration: underline;
}
.link {
  background: url('/icon-link/link.gif') no-repeat 0 0.4em;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.5em;
}
.load {
  background-position: 0 0.3em;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
div.line {
  margin-top: 20px;
  margin-bottom: 12px;
}
div.line hr {
  border-color: #60666c;
  color: #60666c;
}
.flat div.line hr {
  border-color: #fff;
  color: #fff;
}
.gist {
  list-style-type: none;
  margin-left: 0;
  padding-left: 14px;
  background: url(/images/sub3-menu.png) no-repeat 0 0.65em;
}
div.text a.open,
div.list a.open,
div.lead a.open {
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
}
div.load {
  background: none !important;
}
div.load a.load {
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
  margin-left: 0;
}
.main {
  margin-top: 18px;
}
.cb-layout2 .main {
  margin-top: 30px;
  position: relative;
  z-index: 11;
}
#root div.north {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 9;
}
#root div.north div.unit {
  margin: 0;
  width: 100%;
}
#root div.north div.unit div.head h2 {
  margin: 0 5%;
  text-align: center;
  color: #fff;
  font-family: 'Ephesis', helvetica, sans-serif;
  font-size: 42px;
  text-transform: none;
  font-weight: normal;
  line-height: 1.2;
}
#root div.north div.unit div.part {
  width: 100%;
  margin: 0;
}
#root div.north div.unit div.part img {
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
#root div.farwest {
  width: 100%;
}
#root div.farwest div.unit {
  margin: 12px 0;
  width: 100%;
}
#root div.farwest div.head h2 {
  margin: 0;
}
#root div.farwest div.part {
  width: 100%;
  margin: 0;
}
#root div.farwest div.part img {
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
#edit div.farwest {
  border-top: 3px dashed #ccc;
  border-bottom: 3px dashed #ccc;
}
#edit div.base {
  border-top: 3px dashed #ccc;
}
#root div.farnorth {
  text-align: center;
  width: 100%;
  margin-top: 50px;
}
#root div.farnorth:empty {
  margin-top: 0;
}
#root div.farnorth div.unit {
  margin: 0;
  width: 100%;
}
#root div.farnorth div.unit div.head h2 {
  margin: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
}
#root div.farnorth div.part {
  width: 100%;
  margin: 0;
}
#edit div.farnorth {
  min-height: 100px;
}
div.base,
div.south {
  display: flex;
  flex-wrap: wrap;
}
div.base div.unit,
div.south div.unit {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding: 12px;
  text-align: center;
}
div.base div.unit.slim,
div.south div.unit.slim {
  display: flex;
  flex-direction: column;
}
div.base div.unit.slim div.body,
div.south div.unit.slim div.body {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
div.base div.unit.slim div.part,
div.south div.unit.slim div.part {
  flex: 0 0 auto;
}
div.base div.unit.slim div.part.pict,
div.south div.unit.slim div.part.pict {
  flex: 1 0 auto;
}
div.base div.unit div.body,
div.south div.unit div.body {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 57px;
  padding-bottom: 64px;
}
div.base div.unit div.pict,
div.south div.unit div.pict {
  margin-bottom: 30px;
}
div.base div.unit div.pict img,
div.south div.unit div.pict img {
  margin: 0 auto;
}
div.base div.unit div.link,
div.south div.unit div.link,
div.base div.unit div.load,
div.south div.unit div.load {
  background: none;
  margin-top: 25px;
}
div.base div.unit div.link a.open,
div.south div.unit div.link a.open,
div.base div.unit div.load a.open,
div.south div.unit div.load a.open,
div.base div.unit div.link a.load,
div.south div.unit div.link a.load,
div.base div.unit div.load a.load,
div.south div.unit div.load a.load {
  margin-left: 0;
  display: inline-block;
  line-height: 32px;
  text-transform: uppercase;
  font-size: 21px;
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
  padding: 0 18px 0 30px;
  color: #000;
  background: #fff url(/images/link.svg) no-repeat 11px 0.5em;
}
div.base div.unit div.link a.open:hover,
div.south div.unit div.link a.open:hover,
div.base div.unit div.load a.open:hover,
div.south div.unit div.load a.open:hover,
div.base div.unit div.link a.load:hover,
div.south div.unit div.link a.load:hover,
div.base div.unit div.load a.load:hover,
div.south div.unit div.load a.load:hover,
div.base div.unit div.link a.open:focus,
div.south div.unit div.link a.open:focus,
div.base div.unit div.load a.open:focus,
div.south div.unit div.load a.open:focus,
div.base div.unit div.link a.load:focus,
div.south div.unit div.link a.load:focus,
div.base div.unit div.load a.load:focus,
div.south div.unit div.load a.load:focus {
  text-decoration: none;
  color: #820019;
}
div.base div.unit div.link a.open .edit,
div.south div.unit div.link a.open .edit,
div.base div.unit div.load a.open .edit,
div.south div.unit div.load a.open .edit,
div.base div.unit div.link a.load .edit,
div.south div.unit div.link a.load .edit,
div.base div.unit div.load a.load .edit,
div.south div.unit div.load a.load .edit {
  background: none !important;
}
div.base div.unit.pure,
div.south div.unit.pure {
  background: url(/images/base-pure.png) repeat 0 0;
}
div.base div.unit.pure div.body,
div.south div.unit.pure div.body {
  border: 1px solid #8c9397;
}
div.base div.unit.seam,
div.south div.unit.seam {
  background: url(/images/base-seam.png) repeat 0 0;
}
div.base div.unit.seam div.body,
div.south div.unit.seam div.body {
  border: 1px solid #fff;
}
div.base div.unit.flat,
div.south div.unit.flat {
  background: url(/images/base-flat.png) repeat 0 0;
}
div.base div.unit.flat div.body,
div.south div.unit.flat div.body {
  border: 1px solid #fff;
}
#root div.base div.unit.edge,
#root div.south div.unit.edge {
  padding: 0;
}
#root div.base div.unit.edge div.body,
#root div.south div.unit.edge div.body {
  border: none;
  padding: 0;
}
#root div.base div.unit.edge div.head h2,
#root div.south div.unit.edge div.head h2,
#root div.base div.unit.edge div.part,
#root div.south div.unit.edge div.part {
  margin-left: 0;
  margin-right: 0;
}
#root div.base div.unit.edge div.part,
#root div.south div.unit.edge div.part {
  width: 100%;
  margin: 0;
}
div.base {
  margin-top: 20px;
}
div.area div.form label.name {
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
  text-transform: uppercase;
}
div.area div.form input.text,
div.area div.form textarea {
  text-align: center;
  border-radius: 0;
  border: 1px solid #b0b6ba;
  box-shadow: none;
  padding: 0 10px;
  min-height: 46px;
  max-width: 600px;
  margin: 5px auto 15px;
}
div.area div.form select {
  max-width: 600px;
  margin: 5px auto 15px;
  float: none;
}
div.area div.form div.body {
  padding-bottom: 63px;
}
div.area div.form input.submit {
  position: absolute;
  bottom: 45px;
  left: 50%;
  width: 120px;
  margin-left: -60px;
  line-height: 32px;
  text-transform: uppercase;
  font-size: 21px;
  font-weight: normal;
  font-family: 'RobotoCondensedBold', helvetica, sans-serif;
  padding: 0 18px 0 30px;
  color: #000;
  background: #fff url(/images/link.svg) no-repeat 11px 0.5em;
  border: none;
  min-height: 0;
  text-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
div.area div.form input.submit:hover,
div.area div.form input.submit:focus {
  text-decoration: none;
  color: #820019;
}
div.area div.form input.submit:active {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) inset;
}
.grow {
  text-transform: none !important;
}
/* MOOD CONTAINER */
#expo {
  float: left;
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 46.875%;
}
/* IMAGE CONTAINER */
#slides {
  overflow: hidden;
  position: relative;
  max-width: 100% !important;
  max-height: 10000px !important;
  width: 100%;
  z-index: 1;
}
#slides .slide {
  width: 100%;
}
#slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
/* MOOD NAVIGATION */
#expo div.link {
  display: none;
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.5em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'RobotoCondensedLight', helvetica, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.5em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 16px;
  font-size: 1.6rem;
  color: #444;
  font-weight: normal;
  font-family: 'RobotoCondensedLight', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  font-size: 16px;
  font-size: 1.6rem;
  display: inline-block;
  padding: 0.1em 1em;
  min-height: 1.5em;
  border: 1px solid;
  border-color: #690014 #35000a #35000a #690014;
  border-radius: 2px;
  background-color: #820019;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(28, 0, 5, 0.75);
  line-height: 1.5em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #690014 #35000a #35000a #690014;
  background-color: #91001c;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #690014 #35000a #35000a #690014;
  background-color: #4f000f;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.5em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
/*** mobile-navi-multilevel-right-left.less 2014-11-03 ***/
.navi,
#find,
.dock {
  display: none;
}
#mobile-navi {
  position: fixed;
  top: 0;
  width: 240px;
  height: 100%;
  z-index: 2000;
  overflow: scroll;
  background: #fff;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.togglenavigation {
  position: fixed;
  top: 19px;
  display: block;
  width: 40px;
  height: 28px;
  z-index: 2001;
  cursor: pointer;
  color: #820019 !important;
  -webkit-tap-highlight-color: transparent;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  -webkit-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
}
.togglenavigation:active > span {
  background: #590011 !important;
}
.togglenavigation > span {
  left: 0;
  top: 50%;
  position: absolute;
  display: block;
  height: 6px;
  width: 40px;
  margin-top: -3px;
  background: currentColor;
}
.togglenavigation > .tline-1 {
  margin-top: -14px;
}
.togglenavigation > .tline-4 {
  margin-top: 8px;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  -webkit-transition: -webkit-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -o-transition: -o-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -moz-transition: -moz-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
body.cb-toggle-target-active #mobile-navi {
  -o-transition: all 0.218s ease-out;
  -moz-transition: all 0.218s ease-out;
  -webkit-transition: all 0.218s ease-out;
  transition: all 0.218s ease-out;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
body.cb-toggle-target-active .togglenavigation {
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
  -webkit-transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
  transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
}
/* RIGHT */
#mobile-navi {
  right: -240px;
}
.togglenavigation {
  right: 5%;
}
body.cb-toggle-target-active #mobile-navi {
  right: 0;
}
body.cb-toggle-target-active .togglenavigation {
  margin-right: 240px;
}
/* LEFT */
#mobile-navi .navi {
  float: left;
  display: block;
  width: 100%;
}
#mobile-navi .navi .item {
  width: 100%;
  float: left;
  position: relative;
}
#mobile-navi .navi .item .menu {
  display: block;
  color: #000;
  padding: 4px 0;
  padding-right: 32px;
  font-size: 16px;
}
#mobile-navi .navi .item .menu.path {
  color: #820019;
}
#mobile-navi .navi .item .menu:hover,
#mobile-navi .navi .item .menu:focus {
  text-decoration: none;
}
#mobile-navi div.sub1 {
  float: right;
  width: auto;
  margin-right: 20px;
  margin-top: 20px;
}
#mobile-navi div.sub1 > .item {
  border-right: 1px solid #60666c;
  padding: 0 10px;
  width: auto;
}
#mobile-navi div.sub1 > .item.init {
  padding-left: 0;
}
#mobile-navi div.sub1 > .item.exit {
  border: none;
  padding-right: 0;
}
#mobile-navi div.sub1 > .item .menu {
  color: #000;
  line-height: 1;
  padding: 0;
}
#mobile-navi div.sub1 > .item .menu.path {
  color: #60666c;
  background: none;
}
#mobile-navi .navi.sub2 {
  width: 200px;
  margin: 25px 20px;
}
#mobile-navi .navi.sub2 .item .menu {
  text-transform: uppercase;
}
#mobile-navi .navi.sub3 {
  max-height: 0;
  overflow: hidden;
}
#mobile-navi .navi.sub3 .item .menu {
  padding: 5px 0 5px 14px;
  font-size: 14px;
  background: url(/images/sub3-menu.png) no-repeat 0 1em;
}
#mobile-navi div.navi > .item.cb-toggle-target-active > div.navi {
  max-height: 1000px;
  -o-transition: all 2s;
  -moz-transition: all 2s;
  -webkit-transition: all 2s;
  transition: all 2s;
}
#mobile-navi .cb-toggle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 32px;
  width: 20px;
  height: 32px;
  background: url(/images/mnavi.svg) no-repeat 50% 50%;
}
#mobile-navi .cb-toggle:hover,
#mobile-navi .cb-toggle:focus {
  cursor: pointer;
}
#mobile-navi .cb-toggle.cb-toggle-active {
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
#mobile-navi div.sub3 .cb-toggle {
  height: 34px;
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
/******* layout-small.less 2013-1-16 *******/
.desk {
  max-width: 90%;
}
.desk.small {
  width: 100%;
}
#home {
  width: 105px;
  margin: 20px 0 26px 30px;
}
.wave {
  width: 170px;
  top: 36px;
}
@media only screen and (max-width: 767px) {
  .cb-layout2 #head {
    margin-top: 80px;
  }
  .cb-layout2 .main {
    margin-top: 15px;
  }
  #root div.north {
    top: 0;
    height: 100%;
  }
  #root div.north div.unit {
    height: 100%;
  }
  #root div.north div.unit div.head {
    height: 100%;
    display: table;
    width: 100%;
  }
  #root div.north div.unit div.head h2 {
    font-size: 24px;
    vertical-align: middle;
    display: table-cell;
  }
  .vcard .sep {
    display: none;
  }
  .vcard .org,
  .vcard .adr,
  .vcard .street-address,
  .vcard .tel {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .bookingmaskwidth {
    margin-top: -40px !important;
  }
  .cb-layout2 .bookingmaskwidth {
    margin-top: 0 !important;
    top: 0;
  }
  .bookingform {
    display: none;
  }
  #bookingmask {
    height: 40px !important;
    width: 150px;
  }
  #bookingmask .service_booking {
    font-size: 24px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    padding: 0 5%;
    font-size: 22px;
  }
  .cb-layout2 h1 {
    font-size: 22px;
  }
  h3 {
    font-size: 32px;
  }
  h6 {
    font-size: 14px;
  }
  h6 strong {
    font-size: 22px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part,
.area > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 100%;
}
#root div.farnorth {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
@media only screen and (max-width: 767px) {
  div.base div.unit div.head h2,
  div.south div.unit div.head h2,
  div.base div.unit div.part,
  div.south div.unit div.part {
    margin-left: 5%;
    margin-right: 5%;
  }
  div.base div.unit div.part,
  div.south div.unit div.part {
    width: 90%;
  }
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
h1,
h2 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
/*# sourceMappingURL=./screen-small.css.map */